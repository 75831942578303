
  .__json-key__{
    color: rgba(255,94,94,1)
  }
 

  .__json-value__{
    color: rgb(0, 111, 182)
  }
 

  .__json-string__{
    color: rgb(46, 46, 46)

  }
   
  .__json-boolean__{
    color: rgba(102,153,204,1)
  }
 

