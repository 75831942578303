.customStyle {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    height: 50px;
    outline: none;
    padding: 10px;
    width: 350px;
}

.rt-th {
    white-space: unset !important;
}