body {
  background: #F0F0F0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
  padding: 0;  
}

li {
  list-style-type: none;
}

a {
  color: #333333;
  text-decoration: none;
}

table {
 border: 1px solid #F0F0F0;
 border-radius: 4px;
 box-sizing: border-box;
 font-size: 14px;
 line-height: 25px;
 text-align: left;
 margin: 0 20px;

}

thead {
background: #CCCCCC;
border-radius: 4px;
line-height: 30px;
}

td {
  box-sizing: border-box;
  padding: 10px; 
}

th {
  box-sizing: border-box;
  padding: 10px; 
}

tr:nth-child(even) {
   background: #F0F0F0;

} 
  
.react-tabs__tab--selected {
  background: #fff!important;
  border-color: #CCCCCC;
  color: black!important;
  border-radius: 3px 3px 0 0!important;
}

.react-tabs__tab {
  display: inline-block!important;
  border: 1px solid transparent;
  border-bottom: none!important;
  bottom: -1px!important;
  font-size: 14px;
  font-weight: bold;
  position: relative!important;
  list-style: none!important;
  padding: 15px 40px!important;
  cursor: pointer!important;
}


.react-tabs {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  padding: 0 20px 40px 20px!important;
}